import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'

const apiBaseUrl = 'http://43.230.165.80:8088/racemanager'
// const apiBaseUrl = 'http://127.0.0.1:8080/racemanager'
// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl,
  timeout: 9000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
      case 403:
        Vue.prototype.$Jnotification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
        break
      case 500:
        console.log('------error.response------', error.response)
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        // eslint-disable-next-line no-case-declarations
        const type = error.response.request.responseType
        if (type === 'blob') {
          blobToJson(data)
          break
        }
        break
      case 404:
        Vue.prototype.$Jnotification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
        break
      case 504:
        Vue.prototype.$Jnotification.error({ message: '系统提示', description: '网络超时' })
        break
      case 401:
        Vue.prototype.$Jnotification.error({ message: '系统提示', description: '很抱歉，登录已过期，请重新登录', duration: 4 })
        break
      default:
        Vue.prototype.$Jnotification.error({
          message: '系统提示',
          description: data.message,
          duration: 4
        })
        break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      Vue.prototype.$Jnotification.error({ message: '系统提示', description: '网络超时' })
    } else {
      Vue.prototype.$Jnotification.error({ message: '系统提示', description: error.message })
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}
/**
 * Blob解析
 * @param data
 */
function blobToJson (data) {
  const fileReader = new FileReader()
  fileReader.onload = function () {
    try {
      const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      console.log('jsonData', jsonData)
      if (jsonData.status === 500) {
        console.log('jsonData', jsonData)
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log('blob解析fileReader返回err', err)
    }
  }
  fileReader.readAsText(data)
}

export {
  installer as VueAxios,
  service as axios
}
