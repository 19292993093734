<template>
  <el-row class="header">
    <el-col :xs="{ span: 8 }" :sm="{ span: 8 }" :md="{ span: 8 }"
            :lg="{ span: 8, offset: 1 }" :xl="{ span: 8, offset: 1 }">
      <img class="logo" src="../assets/logo.jpg" />
    </el-col>
    <el-col :xs="{ span: 15 }" :sm="{ span: 15 }" :md="{ span: 15 }"
            :lg="{ span: 14 }" :xl="{ span: 14 }">
      <el-menu style="width: 100%;" class="el-menu-demo" mode="horizontal" @select="handleSelect"
               background-color="#15470D"    text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item class="menu-item" index="2" @click="jumpTo('/about')">聯繫我們</el-menu-item>
        <el-menu-item class="menu-item" index="2" @click="jumpTo('/race')">全球賽事</el-menu-item>
        <el-menu-item class="menu-item" index="3" @click="jumpTo('/newsList')">賽馬要聞</el-menu-item>
        <el-menu-item class="menu-item" index="1" @click="jumpTo('/')">首頁</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>
<script>

export default {
  name: 'HomeView',
  data () {
    return {
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    jumpTo (url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.header{
  background-color:#15470D;
  height: 60px;
}
/**导航条logo */
.logo {
  float: left;
  width: 400px;
  height: 30px;
  margin-top: 15px;
}
/**导航条居右 */
.menu-item {
  float: right !important;
}
</style>
