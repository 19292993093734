import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'// ElementUI
import 'element-ui/lib/theme-chalk/index.css' // ElementUI
import '@/assets/iconfont/iconfont.css'
import myHeader from './components/header.vue'
import myCopyright from './components/copyright.vue'
Vue.config.productionTip = false

Vue.use(ElementUI)// ElementUI
// 导入全局组件
// 全局注册组件
Vue.component('my-header', myHeader)
Vue.component('my-copyright', myCopyright)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
