import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/race',
    name: 'race data',
    component: () => import(/* webpackChunkName: "about" */ '../views/race.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  {
    path: '/formGuide',
    name: 'formGuide',
    component: () => import(/* webpackChunkName: "about" */ '../views/formGuide.vue')
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import(/* webpackChunkName: "about" */ '../views/newsList.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
export default router
