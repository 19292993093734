<template>
<div>
    <div id="footer-div">
      <div><el-link class="link"  v-for="(item, index) in urlList" :key="index" @click="goTo(item.url)">{{ item.name }}</el-link></div>
      ©2020-2024 银骏赛马HORSEBANK 版权所有
    </div>
</div>
</template>
<script>

export default {
  name: 'HomeView',
  data () {
    return {
      urlList: [
        {
          name: '国际赛马联盟',
          url: 'https://www.ifhaonline.org'
          // eslint-disable-next-line no-sequences
        },
        {
          name: '中国马术协会',
          url: 'https://www.c-e-a.org.cn'
        },
        {
          name: '第一赛马网',
          url: 'http://www.horsechinaone.com'
        },
        {
          name: '香港赛马会',
          url: 'https://racing.hkjc.com'
        },
        {
          name: '日本中央竞马会',
          url: 'https://www.jra.go.jp'
        },
        {
          name: '澳大利亚赛马会',
          url: 'http://www.racingaustralia.horse'
        },
        {
          name: '英国赛马会',
          url: 'https://www.britishhorseracing.com'
        },
        {
          name: '迪拜赛马会',
          url: 'http://www.emiratesracing.com'
        },
        {
          name: '法国赛马会',
          url: 'https://www.france-galop.com'
        }
      ]
    }
  },
  methods: {
    goTo (url) {
      window.open(url)
    }
  }
}
</script>
<style  lang="scss" scoped>
.link{
  margin-right: 20px;
  line-height: 20px;
  color: #FFFFFF;
}

.el-link.el-link--default:hover{
  color: white;
}
#footer-div {
    width: 100%;
    height: 80px;
    line-height: 40px;
    background: #15470D;
    margin-top: auto; // 重点代码
    color: #FFF;
}
</style>
