<template>
  <div class="page">
    <div id="body">
    <my-header></my-header>
    <el-row>
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }"
              :lg="{ span: 22, offset: 1 }" :xl="{ span: 22, offset: 1 }">
        <el-card class="competition-card">
          <el-row class="competition-btn-row">
            <el-button v-for="(item, index) in competitionBtnList" :key="index" round
              :type="competitionActivite == item.id ? 'success' : ''"
              :class="competitionActivite != item.id ? 'btn-no-activite' : ''">{{ item.value }}</el-button>
            <el-button class="btn-all" type="info" @click="moreRace">今日全部比賽<i class="el-icon-right el-icon--right"></i></el-button>
          </el-row>
          <el-row class="competition-content-row" :gutter="10">
            <el-col :span="4" v-for="item in raceList" :key="item.zlxkey" style="cursor: pointer">
              <el-card>
                <div  @click="getRaceDetail(item.zlxkey,item.hplaceId)">
                <div class="title">{{ item.meetingname }} </div>
                <div class="race">第{{item.racenumber}}場</div>
                <div class="foot">
                  <div class="name">{{ item.location }}</div>
                  <div class="time">{{ item.parseTime }}分鐘</div>
                </div>
              </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }"
              :lg="{ span: 22, offset: 1 }" :xl="{ span: 22, offset: 1 }">
        <el-container>
          <el-aside width="70%">
            <el-row class="banner">
              <el-carousel :interval="3000" arrow="hover" height="200px">
                <el-carousel-item v-for="item in bannerList" :key="item.id" >
                  <el-image  style="height: 200px;width: 100%;" :src="item.bannerImg" fit="fill" @click="goTo(item.bannerUrl)">
                    <div slot="error" class="image-slot">
                      <img class="race-img"  :src="require('../assets/nopic.png')" />
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
            </el-row>
            <el-row :gutter="10" class="news">
              <el-col :span="24">
                <el-row class="news-row">
                  <div class="news-headline">熱門新聞</div>
                  <el-button class="news-btn-all" type="info" @click="moreNews">更多<i class="el-icon-right el-icon--right"></i></el-button>
                </el-row>
              </el-col>
              <el-col :span="8"  @click="getDetail(hotNews.id)">
                <div class="news-title" @click="getDetail(hotNews.id)">
                   {{hotNews.title}}
                  <el-skeleton v-show="false" />
                </div>
                <div class="news-detail" @click="getDetail(hotNews.id)">
                  {{hotNews.msgDesc}}
                  <el-skeleton v-show="false" />
                </div>
              </el-col>
              <el-col :span="16">
                <el-image @click="getDetail(hotNews.id)" class="news-img" :src="hotNews.msgCoverImage" fit="fill">
                  <div slot="error" class="image-slot">
                    <img class="race-img"  :src="require('../assets/nopic.png')" />
                  </div>
                </el-image>
              </el-col>
            </el-row>
          </el-aside>
          <el-main style="width: 30%;">
            <el-row class="news-list">
              <div class="news-list-headline">新聞列表</div>
              <div class="news-list-item" v-for="item in newsList" :key="item.id" @click="getDetail(item.id)">
                <el-col :span="8">
                  <el-image class="news-list-item-img" :src="item.msgCoverImage" fit="fill">
                    <div slot="error" class="image-slot">
                      <img class="race-img"  :src="require('../assets/nopic.png')" />
                    </div>
                  </el-image>
                </el-col>
                <el-col :span="16">
                   <div class="news-list-item-content" >{{item.title}}</div>
                   <div class="news-list-item-time" >{{item.createTime}}</div>
                </el-col>
              </div>
              <el-empty description="no data" v-show="false"></el-empty>
            </el-row>
          </el-main>
        </el-container>
      </el-col>
    </el-row>
    <el-backtop target=".page-component__scroll .el-scrollbar__wrap" :bottom="100">
      <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }">
        UP
      </div>
    </el-backtop>
  </div>
    <my-copyright></my-copyright>
  </div>
</template>

<script>
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
export default {
  name: 'HomeView',
  data () {
    return {
      raceList: {},
      competitionActivite: 0,
      competitionBtnList: [
        { id: 0, value: '即將開賽' }
      ],
      // 热门新闻
      hotNews: {},
      bannerList: {},
      newsList: {},
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
    }
  },
  created () {
    this.initBanner()
    this.initNews()
    this.getHotNews()
    this.getNewRaceList()
  },
  methods: {
    moreRace () {
      this.$router.push('/race')
    },
    moreNews () {
      this.$router.push('/newsList')
    },
    // 跳转新闻详情
    getDetail (id) {
      this.$router.push({ name: 'newsDetail', query: { id: id } })
    },
    // 获取最新的赛事列表
    getNewRaceList () {
      var that = this
      getAction('/api/race/getLatestEvent').then(res => {
        if (res.success) {
          that.raceList = res.result
          console.log(res.result)
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
      })
    },
    // 获取热门新闻
    getHotNews () {
      var that = this
      getAction('/api/news/getHot').then(res => {
        if (res.success) {
          if (res.result.msgCoverImage) {
            res.result.msgCoverImage = getFileAccessHttpUrl(res.result.msgCoverImage)
          }
          that.hotNews = res.result
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
      })
    },
    // 赛事详情
    getRaceDetail (id, placeId) {
      if (id) {
        this.$router.push({ path: '/formGuide', query: { id: id, placeId: placeId } })
      }
    },
    // 加载新闻
    initNews () {
      var that = this
      getAction('/api/news/list', {
        pageNo: 1,
        pageSize: 7
      }).then(res => {
        if (res.success) {
          for (let i = 0; i < res.result.records.length; i++) {
            if (res.result.records[i].msgCoverImage) {
              res.result.records[i].msgCoverImage = getFileAccessHttpUrl(res.result.records[i].msgCoverImage)
              console.log(res.result.records[i].msgCoverImage)
            }
          }
          that.newsList = res.result.records
          console.log(res.result)
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
      })
    },
    // 加载新闻
    initBanner () {
      var that = this
      getAction('/api/banner/list').then(res => {
        if (res.success) {
          for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].bannerImg) {
              res.result[i].bannerImg = getFileAccessHttpUrl(res.result[i].bannerImg)
              console.log(res.result[i].bannerImg)
            }
          }
          that.bannerList = res.result
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {
      })
    },
    goTo (url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
body {
  display: block;
  padding: 0;
  margin: 0;
}

.page {
  min-height: 100vh;
  background-color: #EFEFEF !important;
}

.nav-container {
  display: flex;
  /* 使用flex布局 */
  justify-content: center;
  /* 水平居中对齐 */
}

/**即将开赛的赛事 */
.competition-card {
  margin-top: 20px;

  /**按钮行 */
  .competition-btn-row {
    text-align: left;
    width: 100%;

    /**未选中样式 */
    .btn-no-activite {
      border: none !important;
    }

    .btn-all {
      background-color: #EFEFEF;
      border: none;
      color: #000000;
      float: right;
    }
  }

  .competition-content-row {
    margin-top: 10px;

    .title {
      font-size: 16px;
      text-align: left;
      font-weight: bold;
    }
   .race{
     font-size: 16px;
     height: 45px;
     line-height: 45px;
     text-align: center;
   }
    .foot {
      margin-bottom: 10px
    }

    .name {
      color: #909399;
      font-size: 14px;
      float: left;
    }

    .time {
      color: #909399;
      font-size: 14px;
      float: right;
    }
  }
}

/**滚动广告 */
.banner {
  height: 200px;
  margin: 20px 0px 20px;
  padding: 0px !important;
}

/**新闻行 */
.news {
  height: 480px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  overflow: hidden;
  color: #303133;
  transition: .3s;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 0px !important;

  .news-row {
    height: 480px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;

    .news-headline {
      height: 40px;
      line-height: 40px;
      float: left;
      font-size: 20px;
      text-align: left;
      font-weight: bold;
      width: 50%;
    }

    .news-btn-all {
      height: 40px;
      background-color: #EFEFEF;
      border: none;
      color: #000000;
      float: right;
    }
  }
}

.news-title {
  height: 100px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
}

.news-detail {
  height: 200px;
  overflow: hidden;
  text-align: left;
  color: #909399;
  font-size: 15px;
  line-height: 30px;
}

.news-img {
  width: 100%;
  height: 400px;
}

/**新闻列表 */
.news-list {
  width: 100%;
  height: 700px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background-color: #FFF;
  transition: .3s;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .news-list-headline {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      text-align: left;
      font-weight: bold;
      width: 100%;
    }
    /**新闻项*/
  .news-list-item{
    height: 80px;
    margin: 10px 0px 10px;
    .news-list-item-img{
      height: 80px;
      width: 95%;
    }
    .news-list-item-content{
      height: 60px;
      line-height: 20px;
      text-align: left;
      font-size: 16px;
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .news-list-item-time{
      height: 20px;
      color: #909399;
      font-size: 14px;
      text-align: left;
      margin-bottom: 0px;
    }
  }
}
.el-card__body, .el-main {
    padding-right: 0px !important;
}
#body {
  width: 100%;
  min-height: calc( 100vh - 80px );
  display: flex;
  flex-direction: column;
}

</style>
